import { TFunction } from 'i18next'

export type Tenant = {
    id: string
    name: string
    description: string
    createdAt: string
    updatedAt: string
    dependencies: Array<{
      id: string
      name:string
      status:string
    }>
    status: string
    subscriptionId: string
  }

export type TenantModalProps= {
    heading?: string
    isModalOpen: boolean
    handleModalClose: any
    handleSaveClick: any
    tenant: Tenant
    hasWrite?: boolean
  }

export type DeleteTenantModalProps = {
    isModalOpen: boolean
    handleModalClose: () => any
    handleSaveClick: (e: any) => any
    tenant: Tenant
  }

export type TranslateType = {
   t: TFunction

}

export const defaultTenant: Tenant = {
  id: '',
  name: '',
  description: '',
  createdAt: '',
  updatedAt: '',
  dependencies: [],
  status: '',
  subscriptionId: '',
}
