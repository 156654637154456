import React, { useCallback, useState } from 'react'
import { Dot } from '@jarvis-catalyst/ui-components'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  Grid,
  Pagination,
  Table,
  TextField,
  Typography,
  Icon,
  Button,
  ButtonGroup,
  Box,
  Autocomplete,
} from '@jarvis-catalyst/jarvis-ui-kit'
import type { Option } from '@jarvis-catalyst/ui-components/lib/components/select'
import useTenantsContext from '../../contexts/TenantsContext'
import { chopOff } from '../../utils/stringHelpers'
import { stateMap, tenantStates, getTenantStateSelectorOptions } from './stateMapping'

import './TenantsListing.css'

import useLanguageAndTenantId from '../../../../hooks/useLanguageAndTenantId'
import ManageSite from '../manage-site/ManageSite'
import { useManageSite } from './useManageSite'
import type { Tenant } from '../../tenant.type'

const WAIT_TIME = 500

type TenantsListingProps = {
  handleEdit: (tenant: Tenant) => void
  handleDelete: (tenant: Tenant) => void
  setIsAddModalOpen: (isOpen: boolean) => void
  hasWrite: boolean
}

const TenantsListing = function ({ handleEdit, handleDelete, setIsAddModalOpen, hasWrite }: TenantsListingProps) {
  const {
    isOpen: manageSiteOpen,
    tenant: siteManageTenant,
    setOpen: setManageSiteOpen,
    setClose: setManageSiteClose,
  } = useManageSite()

  const { t } = useTranslation()
  const { tenants, isLoading, totalPages, currentPage, searchValue, dispatch } = useTenantsContext()
  const { language } = useLanguageAndTenantId()
  const [searchInput, setSearchInput] = useState<string>(searchValue || '')
  const [selectedOption, setSelectedOption] = useState<Option>({
    value: 'ALL',
    label: 'All',
  })

  React.useEffect(() => {
    setSearchInput(searchValue)
  }, [searchValue])

  const handleStatusChange = (option: Option) => {
    dispatch({
      type: 'UPDATE_TENANT_STATUS',
      payload: option?.value || 'ALL',
    })
  }

  const selectorOptions = useCallback(getTenantStateSelectorOptions, [t])

  const handleSearchValueChange = (value: string) => dispatch({ type: 'UPDATE_SEARCH_VALUE', payload: value })

  const debounced = useCallback(debounce(handleSearchValueChange, WAIT_TIME), [])

  const getId = ({ id }: Tenant) => (
    <Button
      variant="outlined"
      size="small"
      startIcon={<Icon name="Copy" />}
      onClick={() => navigator.clipboard.writeText(id)}
    >
      {null}
    </Button>
  )

  const getState = ({ status, dependencies }: Tenant) => {
    const tenantStateDetails = stateMap(t, status, dependencies)
    return (
      <span title={tenantStateDetails.message}>
        <Dot customColor={tenantStateDetails.color} />
      </span>
    )
  }

  const handlerChangePage = (_: React.ChangeEvent<unknown>, page: number) =>
    dispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })

  const getTableOptions = (tenant: Tenant) => (
    <div className="text-right">
      <ButtonGroup size="small">
        <Button
          variant="outlined"
          size="small"
          disabled={tenant.status === tenantStates.INACTIVE}
          onClick={() => setManageSiteOpen(tenant)}
          startIcon={<Icon name="Site" />}
        >
          {null}
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={!hasWrite || tenant.status === tenantStates.INACTIVE}
          onClick={() => handleEdit(tenant)}
          startIcon={<Icon name="Edit" />}
        >
          {null}
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={!hasWrite || tenant.status === tenantStates.INACTIVE}
          onClick={() => handleDelete(tenant)}
          startIcon={<Icon name="Delete" />}
        >
          {null}
        </Button>
      </ButtonGroup>
    </div>
  )

  const getDescription = (description: string | null): string => (description ? chopOff(description, 50) : ' ')

  const columns = [
    { key: 'id', content: <Typography variant="H500">{t('ID')}</Typography> },
    { key: 'name', content: <Typography variant="H500">{t('NAME')}</Typography> },
    { key: 'description', content: <Typography variant="H500">{t('DESCRIPTION')}</Typography> },
    { key: 'created', content: <Typography variant="H500">{t('CREATED')}</Typography> },
    { key: 'state', content: <Typography variant="H500">{t('STATE')}</Typography> },
    { key: 'actions', content: '' },
  ]

  const rows = tenants.map((tenant: Tenant) => ({
    id: getId(tenant),
    name: chopOff(tenant.name, 30),
    description: getDescription(tenant.description),
    created: new Date(tenant.createdAt).toLocaleDateString(language),
    state: getState(tenant),
    actions: getTableOptions(tenant),
  }))

  return (
    <>
      <Grid
        container
        columnSpacing={2}
      >
        <Grid
          item
          lg={2}
        >
          <Autocomplete
            placeholder={t('TENANT_STATE_SELECTION')}
            onChange={(_, option) => {
              const newOption = (option as Option) || { value: 'ALL', label: 'All' }
              setSelectedOption(newOption)
              handleStatusChange(newOption)
            }}
            options={selectorOptions(t)}
            value={selectedOption}
            disableClearable
          />
        </Grid>
        <Grid
          item
          lg={4}
        >
          <TextField
            fullWidth
            autoFocus
            label={t('TENANTS')}
            icon="Search"
            onChange={(event) => {
              const { value } = event.target
              setSearchInput(value)
              debounced(value)
            }}
            value={searchInput}
            placeholder={t('SEARCH_TENANTS_PLACEHOLDER')}
          />
        </Grid>
        <Grid
          item
          lg={6}
        >
          <Box sx={{ textAlign: 'end' }}>
            <Button
              size="large"
              variant="contained"
              startIcon={<Icon name="Plus" />}
              disabled={!hasWrite}
              onClick={() => setIsAddModalOpen(true)}
            >
              {t('CREATE_TENANT')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Table
        columns={columns}
        rows={rows}
        loading={isLoading}
      />
      {!isEmpty(tenants) && (
        <Grid
          container
          direction="row-reverse"
        >
          <Grid item>
            <Pagination
              color="primary"
              currentpage={currentPage}
              totalpages={totalPages}
              onChange={handlerChangePage}
            />
          </Grid>
        </Grid>
      )}
      <ManageSite
        onClose={setManageSiteClose}
        open={manageSiteOpen}
        tenant={siteManageTenant}
        hasWrite={hasWrite}
      />
    </>
  )
}

export default TenantsListing
