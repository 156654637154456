import { useState } from 'react'
import type { Tenant } from '../../tenant.type'
import { sitesGetAllResetAction, sitesBatchResetAction } from '../../../../store/modules/sites/actions'

type ManageSiteHook = {
  isOpen: boolean
  tenant: Tenant | null
  setOpen: (tenant: Tenant) => void
  setClose: () => void
}

export const useManageSite = (): ManageSiteHook => {
  const [isOpen, setIsOpen] = useState(false)
  const [tenant, setTenant] = useState<Tenant | null>(null)

  return {
    isOpen,
    tenant,
    setOpen: (newTenant: Tenant) => {
      setIsOpen(true)
      setTenant(newTenant)
    },
    setClose: () => {
      setIsOpen(false)
      setTenant(null)
      sitesGetAllResetAction()
      sitesBatchResetAction()
    },
  }
}
