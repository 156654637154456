import type { Option } from '@jarvis-catalyst/ui-components/lib/components/select'

export const tenantStates = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PROVISIONING: 'provisioning',
  ERROR: 'error',
}

const tenantStateColors = {
  RED: '#FF5630',
  GREEN: '#36B37E',
  ORANGE: '#FFA500',
  GRAY: '#6B778C',
}

const getDependentsWithErrorStatus = (dependencies: any) => {
  const depWithErrors = dependencies.filter(
    (dependency: { status: string }) => dependency.status === tenantStates.ERROR,
  )
  const depNames = depWithErrors.map((dependent: any) => dependent.name)

  return depNames.join(', ')
}

export const stateMap = (t: (key: string) => string, status: string, dependencies: any) => {
  if (status === tenantStates.ERROR) {
    return {
      message: `${t('ERROR_TENANT_MESSAGE')} ${getDependentsWithErrorStatus(
        dependencies,
      )}`,
      color: tenantStateColors.RED,
    }
  }

  const states = {
    [tenantStates.ACTIVE]: {
      message: t('ACTIVE_TENANT_MESSAGE'),
      color: tenantStateColors.GREEN,
    },
    [tenantStates.INACTIVE]: {
      message: t('INACTIVE_TENANT_MESSAGE'),
      color: tenantStateColors.ORANGE,
    },
    [tenantStates.PROVISIONING]: {
      message: t('PROVISIONING_TENANT_MESSAGE'),
      color: tenantStateColors.GRAY,
    },
  }

  return states[status]
}

export const getTenantStateSelectorOptions = (t: (key: string) => string): Option[] => [
  { label: t('ALL'), value: 'ALL' },
  ...Object.entries(tenantStates).map(([key, value]) => ({
    label: t(key),
    value,
  })),
]
